<template>
<div>
  <b-col class="d-flex justify-content-center my-2">
    <slot name=icon ><p class="text-danger text-center">--error: required slot undefined--</p></slot>
  </b-col>

  <b-col md="12" class="my-2">
    <custom-input
      v-if="variables"
      class="my-1"
      id="custom-input-r1"
      :possibleValues="variables"
      v-model="agent.register_1"
      advanced
      >
      <template #label>
        <span>{{ $t(`agent.list.${i18nKey}.register_1.title`) }}</span>
        <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
        <span class="float-right text-right mx-25 text-secondary"><slot name="description-input1">{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</slot></span>
      </template>
    </custom-input>
      <div v-else class="mb-2">
        <b-skeleton class="mb-25" width="25%" height="12px" />
        <b-skeleton class="mb-25" width="100%" height="37px" />
        <b-skeleton class="mb-25" width="25%" height="14px" />
      </div>
  </b-col>

  <b-col md="12" class="my-2">
    <custom-input
      v-if="variables"
      class="my-1"
      id="custom-input-rd"
      :possibleValues="variables"
      v-model="agent.register_destiny"
      advanced
      >
      <template #label>
        <span>{{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}</span>
        <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
        <span class="float-right text-right mx-25 text-secondary"><slot name="description-input2">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</slot></span>
      </template>
    </custom-input>
      <div v-else class="mb-2">
        <b-skeleton class="mb-25" width="25%" height="12px" />
        <b-skeleton class="mb-25" width="100%" height="37px" />
        <b-skeleton class="mb-25" width="25%" height="14px" />
      </div>
  </b-col>
</div>
</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BFormCheckbox,
  BSidebar,
  BLink,
  BButton,
  BCollapse,
  BModal,
  BSkeleton
} from 'bootstrap-vue'

import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import HelperOptions from "@/custom/class/HelperOptions.js";
import SourceValue from "@/custom/class/SourceValue.js";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions } from "vuex";
import Sources from '@/custom/class/Enum/Sources.js'
import VSelect from 'vue-select'
import HelperTooltip from '@/layouts/components/HelperTooltip'
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";

  import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

    components: {
      BContainer,
      BCol,
      BRow,
      BFormCheckbox,
      BSidebar,
      VariablesPanel,
      CustomInput,
      HelperOptions,
      SourceValue,
      BLink,
      VuePerfectScrollbar,
      BButton,
      BCollapse,
      BModal,
      VSelect,
      BSkeleton,
      HelperTooltip
    },
    props: {
      actionID: {
        type: [ Number, String ],
        required: true
      },
      uuid: {
        type: String,
        required: true
      },
      middlewareID: {
        type: Number,
        required: true
      },
      agentID: {
        type: Number,
        default: undefined,
      },
      value: {
        type: NormalizedAgent,
        default: undefined
      },
      width: {
        type: String,
        default: '40%'
      },
      i18nKey: {
        type: String,
        required: true
      },
      agentBlockID: {
        type: [ Number, String ],
        default: 1,
      },
      variables:{
        type: Object,
        default: undefined
      }
    },
    data() {
      return {
        defaultAgent: {
          id: null,
          enum_agent_action_id: this.actionID,
          agent_block_id: this.agentBlockID,
          register_1: {
            source: 7,
            value: ""
          },
          register_2: {
            source: 7,
            value: "",
          },
          register_destiny: {
            source: 10,
            value: ""
          },
          execution_order: 1,
          fatal_on_fail: true,
        },
        uuidMap: {},
        full: false,

        variant_map: null,
        isSaving: false,
      }
    },
    computed: {
      agent: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      agent_block_id() {
        return this.agentBlockId || this.agentBlock
      },
      sourceOptions() {
        return new Sources().items
      },
      toSaveRegs() {
          return {
            register_1: true,
            register_2: false,
            register_destiny: true,
          }
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
      init() {
        DefaultAgent.fetchAgentByID(this)
        this.$nextTick(() => { 
          this.full = this.checkOptionalRegisterValue()
        })
      },
      save() {
        return DefaultAgent.save(this)
      },
      validate() {
        return DefaultAgent.validate(this)
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
      checkOptionalRegisterValue() {
        const registers = ['register_1', 'register_2', 'register_destiny']
        let fullState = false

        registers.forEach((register) => {
          if (this.agent[register].source) {
            fullState = true
          }
        })
        return fullState
      },
    }
  }
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}

</style>